.main_wishlist {
    display: flex;
    flex-direction: column;
    min-height: 90vh;
    text-align: start;
    padding: 20px 100px 0 100px;
}

.main_wishlist h1 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.wishlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
}

.wishlist_card {
    width: 280px;
    margin: 30px 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
}

.wishlist_card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.wishlist_image {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.details {
    padding: 10px;
    text-align: start;
}

.name {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

.property_location {
    font-size: 14px;
    margin-bottom: 5px;
}

.description {
    font-size: 14px;
    margin: 5px 0;
}

.price {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.new-price {
    color: black;
    font-weight: bold;
}

.span_price {
    font-size: 14px;
    color: #666;
}

.btn_section {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.explore_btn {
    padding: 10px;
    color: #fff;
    background-color: rgb(38, 38, 38);
    border: none;
    border-radius: 12px;
    transition: 0.3s ease;
}

.explore_btn:hover {
    background-color: rgb(0, 0, 0);
}

.button_div {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.remove_all_button {
    display: flex;
    font-size: 11px;
    max-width: 100px;
    align-items: center;
    justify-content: center;
}