.trip_container{
    display: flex;
    flex-direction: column;
    min-height: 90vh;
    text-align: start;
    padding: 20px 100px 0 100px;
} 
.trip_container h1{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
} 
.main_trip{
    height: 100vh;
}
.main_trip h3{
    padding-top: 20px;
}

.explore_btn{
    padding: 10px;
    color: #fff;
    background-color: rgb(38, 38, 38);
    border: none;
    border-radius: 12px;
    transition: 0.3s ease;
}
.explore_btn:hover{
    padding: 10px;
    color: #fff;
    background-color: rgb(0, 0, 0);
    border: none;
    border-radius: 12px;
}