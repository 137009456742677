.booking_container {
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  text-align: start;
  padding: 20px 100px 0 100px;
}

.booking_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.booking_header h1 {
  font-size: 20px;
}

.booking_container h1 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.main_booking {
  height: 100vh;
}

.main_booking h3 {
  padding-top: 20px;
}

.explore_btn {
  padding: 10px;
  color: #fff;
  background-color: rgb(38, 38, 38);
  border: none;
  border-radius: 12px;
  transition: 0.3s ease;
}

.explore_btn:hover {
  padding: 10px;
  color: #fff;
  background-color: rgb(0, 0, 0);
  border: none;
  border-radius: 12px;
}

.bill-container {
  max-width: 600px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(to bottom right, #f2f2f2, #ffffff);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.bill-title {
  text-align: center;
  color: #333;
  margin-bottom: 15px;
}

.bill-details p {
  margin: 8px 0;
}

.bill-details strong {
  font-weight: bold;
}

.Wish_Dlete_button {
  border: 1 px solid black;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .booking_container {
    padding: 20px;
  }

  .bill-container {
    padding: 10px;
  }

  .bill-details p {
    font-size: 14px;
  }
}