.category_container {
    display: flex;
  }
  
  .main_content {
    flex: 1;
  }
  
  .category_header {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .category_table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .category_table th,
  .category_table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .category_table th {
    background-color: #f2f2f2;
  }
  
  .category_row:hover {
    background-color: #f2f2f2;
  }

  .category_table i{
    cursor: pointer;
  }
  