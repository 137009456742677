.main_text {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 40px;
    color: #333;
}

.services {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.service_card {
    width: 250px;
    height: 250px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: transform 0.3s ease;
}

.service_card:hover {
    transform: translateY(-10px);
}

.card_container {
    background-color: #008DDA;
    padding: 20px;
}

.card_container img {
    max-width: 100px;
    height: auto;
    display: block;
    margin: 0 auto;
}

.service_details {
    padding: 20px;
}

.service_details h3 {
    font-size: 20px;
    color: #333;
    margin-bottom: 5px;
}

.service_details p {
    font-size: 12px;
    color: #666;
    line-height: 1.5;
}