.review_container {
    display: flex;
    flex-direction: column;
    min-height: 90vh;
    text-align: start;
    padding: 20px 100px 0 100px;
    align-items: center;
    background-color: #f5f5f5;
    font-family: 'Arial', sans-serif;
    color: #333;
}

.review_rating_section {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 800px;
}

.rating_section {
    padding: 10px;
}

.review_section textarea {
    padding: 10px;
    width: 50vw;
    max-width: 700px;
    height: 150px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    resize: vertical;
    transition: border-color 0.3s;
}

.review_section textarea:focus {
    border-color: #007bff;
    outline: none;
}

.review_rating_section h4,
.review_rating_section h5 {
    margin: 10px 0;
    text-align: center;
}

.review_rating_section h4 {
    font-size: 1.5em;
    color: #007bff;
}

.review_rating_section h5 {
    font-size: 1.2em;
    color: #555;
}

.review_section {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
}

.post_btn {
    height: 40px;
}

@media (max-width: 768px) {
    .review_container {
        padding: 20px 10px 0 10px;
    }

    .review_rating_section {
        padding: 20px;
    }

    .review_section textarea {
        width: calc(100vw - 20px);
        max-width: calc(100vw - 20px);
    }

    .review_rating_section h4 {
        font-size: 1.2em;
    }

    .review_rating_section h5 {
        font-size: 1em;
    }

    .post_btn {
        width: 100%;
    }
}