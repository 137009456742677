.contact_body {
  font-family: 'Arial', sans-serif;
  background-image: linear-gradient(to top, #a8edea 0%, #fed6e3 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.contact_container {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 40px;
  max-width: 500px;
  width: 100%;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.contact_container h2 {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}

.contact_form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form_group {
  display: flex;
  flex-direction: column;
}

.form_group label {
  text-align: start;
  font-size: 1rem;
  color: #555;
  margin-bottom: 8px;
  font-family: 'Poppins', sans-serif;
}

.contact_form_input,
.contact_form_textarea {
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  transition: all 0.3s ease;
  background: #f9f9f9;
}

.contact_form_input:focus,
.contact_form_textarea:focus {
  border-color: #2575fc;
  box-shadow: 0 0 10px rgba(37, 117, 252, 0.2);
  outline: none;
  background: #fff;
}

.contact_form_textarea {
  resize: none;
}

.contact_form_button {
  padding: 15px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #2575fc;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  text-align: center;
}

.contact_form_button:hover {
  background-color: #1a5fbf;
}

.contact_form_button:active {
  background-color: #13449a;
}