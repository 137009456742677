.about_body {
  font-family: 'Arial', sans-serif;
  background: #f3f4f6;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: start;
}

.about_container {
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 40px;
  max-width: 800px;
  width: 100%;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.about_nav p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.about_nav p span {
  cursor: pointer;
  transition: color 0.1s;
}

.about_nav p span:hover {
  color: black;
}

.logo_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.logo {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
}

.logo_container h5 {
  font-size: 1.5rem;
  color: #333;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  margin: 0;
}

.text_container {
  line-height: 1.6;
  color: #333;
  font-family: 'Poppins', sans-serif;
}

.text_container h4 {
  font-size: 1.25rem;
  color: #2575fc;
  margin-top: 20px;
  margin-bottom: 10px;
}

.text_container p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .about_container {
    padding: 20px;
  }

  .text_container h4 {
    font-size: 1.125rem;
  }

  .text_container p {
    font-size: 0.875rem;
  }
}