.main_text {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main_text span {
    font-weight: 600;
    /* text-decoration: underline; */
}

.search_property {
    display: block;
    width: 80%;
    max-width: 300px;
    margin: 0 auto 20px auto;
    padding: 10px 15px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

.search_property:focus {
    border-color: #007bff;
    outline: none;
}

.property {
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.card {
    width: 280px;
    margin: 30px 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
}

.card:hover {
    /* transform: scale(1.005); */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.details {
    padding: 10px;
    text-align: start;
}

.name {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

.description {
    font-size: 14px;
    margin: 5px 0;
}

.rating {
    text-align: start;
    font-size: 13px;
    text-decoration: underline;
}

.price {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.new-price {
    color: black;
    font-weight: bold;
}

.old-price {
    color: rgba(0, 0, 0, 0.776);
    text-decoration: line-through grey;
}

.card_img_container {
    position: relative;
    width: 100%;
    height: 250px;
}

.favorite_icon {
    position: absolute;
    top: -245px;
    right: -120px;
    font-size: 24px;
    color: black;
    cursor: pointer;
    transition: font-size 0.2s ease;
}

.favorite_icon:hover {
    font-size: 28px;
}


.favorite_icon.red .MuiSvgIcon-root {
    color: red;
}

.favorite_icon.black .MuiSvgIcon-root {
    color: rgb(68, 65, 65);
}


/* Paging */
.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-bottom: 20px;
}

.page-link {
    padding: 8px 12px;
    border: 1px solid #ccc;
    background-color: #f8f9fa;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.page-link:hover {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
}

.page-link:focus {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
}

/* Skeleton CSS */
.skeleton {
    background-color: #ddd;
    border-radius: 4px;
    width: 100%;
    min-height: 250px;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
}

.skeleton::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -150px;
    height: 100%;
    width: 150px;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    animation: loading 1.5s infinite;
}

@keyframes loading {
    0% {
        left: -150px;
    }

    50% {
        left: 100%;
    }

    100% {
        left: 100%;
    }
}

.skeleton-details {
    background-color: #ddd;
    border-radius: 4px;
    height: 20px;
    width: 70%;
    margin: 10px auto;
}

.skeleton-text {
    background-color: #ddd;
    border-radius: 4px;
    height: 14px;
    width: 90%;
    margin: 5px auto;
}
